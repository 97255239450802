import React, { cloneElement, FunctionComponent, useCallback, useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from './assets/arrow-down.svg';
import useStyles from './styles';
import { IProps } from './types';

// DEPRECATED, will be changed to mui menu
const DropDownButton: FunctionComponent<IProps> = ({ menu, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const classes = useStyles({ isMenuVisible });

  const handleClick = () => {
    setIsMenuVisible(true);
  };

  const handleClose = useCallback(() => {
    setIsMenuVisible(false);
  }, []);

  return (
    <>
      <div
        className={classes.layout}
        ref={ref}
        onClick={handleClick}
        onKeyDown={() => undefined}
        role="button"
        tabIndex={0}
      >
        {children}
        <div className={classes.arrowDown}>
          <ArrowDownIcon />
        </div>
      </div>
      {/* WTF ???? TODO Do Refactoring me */}
      {cloneElement(menu, { isVisible: isMenuVisible, onClose: handleClose, anchor: ref })}
    </>
  );
};

export default DropDownButton;
