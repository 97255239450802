import { makeStyles } from '@mui/styles';
import { Color } from '../../app/consts/theme';

export default makeStyles({
  layout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    width: 'fit-content',
    padding: '5px 10px',
    borderRadius: 5,
    borderBottomRightRadius: 0,
    boxSizing: 'border-box',
    backgroundColor: (props: { isMenuVisible: boolean }) => (props.isMenuVisible ? Color.DARK2 : 'inherit'),
    cursor: 'pointer',
  },
  arrowDown: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12,
  },
});
