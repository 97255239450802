import React, { FunctionComponent, useCallback, useState, useRef, ChangeEvent, useEffect, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { IGridProps } from './types';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import useStyles from './styles';
import useEnterEscKeyHandler from '../../../hooks/useEnterEscKeyHandler';

const GridHeader: FunctionComponent<IGridProps> = forwardRef<HTMLDivElement, IGridProps>(
  ({ title, showButtons, onFilter, onCreate }) => {
    const classes = useStyles();
    const titleRef = useRef<HTMLInputElement>(null);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [gridTitle, setGridTitle] = useState(title);

    useEnterEscKeyHandler(
      titleRef,
      (key) => {
        if (key === 'Escape') {
          setIsFilterActive(false);
        }
      },
      !isFilterActive,
    );

    const intl = useIntl();

    useEffect(() => {
      setIsFilterActive(false);
    }, [title]);

    useEffect(() => {
      if (!isFilterActive) {
        setGridTitle(title);
        onFilter?.('');
      } else {
        setGridTitle('');
        titleRef.current?.focus();
      }
    }, [isFilterActive, title]);

    const handleTitleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        onFilter?.(value);
        setGridTitle(value);
      },
      [onFilter],
    );

    const handleSearchClick = useCallback(() => {
      setIsFilterActive(true);
    }, []);

    const handleCloseClick = useCallback(() => {
      setIsFilterActive(false);
    }, []);

    const handleCreateClick = useCallback(() => {
      onCreate?.();
    }, [onCreate]);

    return (
      <div className={classes.header}>
        <input
          className={classes.title}
          spellCheck="false"
          ref={titleRef}
          value={gridTitle}
          readOnly={!isFilterActive}
          placeholder={intl.formatMessage({
            id: 'search',
          })}
          onChange={handleTitleChange}
          id="grid-search"
        />
        {isFilterActive
          ? showButtons && (
              <div
                className={classes.headerButton}
                onClick={handleCloseClick}
                onKeyDown={handleCloseClick}
                role="button"
                tabIndex={0}
              >
                <CloseIcon />
              </div>
            )
          : showButtons && (
              <>
                <div
                  className={classes.headerButton}
                  onClick={handleCreateClick}
                  onKeyDown={handleCreateClick}
                  id="grid-add"
                  role="button"
                  tabIndex={0}
                >
                  <PlusIcon />
                </div>
                <div
                  className={classes.headerButton}
                  onClick={handleSearchClick}
                  onKeyDown={handleSearchClick}
                  id="grid-search"
                  role="button"
                  tabIndex={0}
                >
                  <SearchIcon />
                </div>
              </>
            )}
      </div>
    );
  },
);

export default GridHeader;
