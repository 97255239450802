import cls from 'classnames';
import React from 'react';
import { SideBarItemsEnum } from '../../pages/Applications/View/types';
import useStyles from './styles';
import { IProps } from './types';

const PageSidebar: React.FC<IProps> = (props) => {
  const { selected, disabled, items } = props;
  const classes = useStyles();

  return (
    <div className={classes.sidebar}>
      {items.map((item, i) => (
        <div
          key={item.title}
          className={cls({
            [classes.itemActive]: item.title === selected,
            [classes.itemDisabled]: disabled && item.title !== SideBarItemsEnum.General,
          })}
          onClick={item.onClick}
          onKeyDown={() => null}
          aria-disabled={disabled}
          role="button"
          tabIndex={i}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default PageSidebar;
