import { makeStyles } from '@mui/styles';
import { Color, Font } from '../../app/consts/theme';

const useStyles = makeStyles({
  editButton: {
    gridArea: 'edit',
    justifySelf: 'right',
    marginTop: 2,
    cursor: 'pointer',
    color: Color.WHITE3,
    fontFamily: Font.BRAND_MEDIUM,
    div: {
      borderBottom: 'solid 1px',
    },
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  divider: {
    maxHeight: 0,
    gridArea: 'divider',
    opacity: 0.4,
    border: 'solid 1px #6D747D',
  },
  cardIcon: {
    gridArea: 'icon',
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#6D747D',
  },
  cardName: {
    gridArea: 'name',
    alignSelf: 'end',
    justifySelf: 'start',
    lineHeight: '28px',
    fontFamily: Font.BRAND_MEDIUM,
    fontSize: 24,
    fontStyle: 'normal',
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'default',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
    textAlign: 'left',
  },
  applicationCard: {
    display: 'grid',
    overflow: 'hidden',
    gridTemplateRows: '74px 1fr 1fr',
    gridTemplateAreas: `"icon edit" "divider divider" "name name"`,
    width: 370,
    height: 187,
    borderRadius: 10,
    border: 'solid 1px',
    borderColor: Color.WHITE0,
    backgroundColor: Color.DARK3,
    padding: 24,
    boxSizing: 'border-box',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2), 0px 1px 12px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      transition: 'transform 0.4s ease',
      transform: 'translateY(-8px)',
    },
    transition: 'transform 0.2s ease',
    transform: 'translateY(0px)',
    willChange: 'transform',
  },
});

export default useStyles;
