import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import SnackbarUtils from 'utils/SnackbarUtils';
import { createIntl, createIntlCache } from 'react-intl';
import LOCALE_DATA from '../locales';

enum Methods {
  Get = 'get',
  Put = 'put',
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete',
}

class Api {
  private api: AxiosInstance;

  private intl;

  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_BASEURL}`,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    const cache = createIntlCache();
    this.intl = createIntl(
      {
        locale: 'en',
        messages: LOCALE_DATA.en,
      },
      cache,
    );
  }

  private async caller(
    method: Methods,
    address: string,
    params: AxiosRequestConfig | FormData | Record<string, unknown> = {},
    config: AxiosRequestConfig = {},
  ): Promise<{ data: unknown }> {
    return this.api[method](address, params, config).catch((e) => {
      let message = this.intl.formatMessage({ id: 'httpExceptions.SOMETHING_WENT_WRONG' });
      if (e?.response?.data?.error && this.intl.messages[`httpExceptions.${e.response.data.error}`]) {
        message = this.intl.formatMessage({ id: `httpExceptions.${e.response.data.error}` });
      }
      SnackbarUtils.error(message);

      throw e.message;
    });
  }

  public async get(
    address: string,
    params: Record<string, unknown> = {},
    config: AxiosRequestConfig = {},
  ): Promise<unknown> {
    const response = await this.caller(Methods.Get, address, {
      params,
      paramsSerializer: qs.stringify,
      ...config,
    });
    return response.data;
  }

  public async put(address: string, params: Record<string, unknown> = {}): Promise<unknown> {
    const response = await this.caller(Methods.Put, address, params);
    return response.data;
  }

  public async post(
    address: string,
    params: FormData | Record<string, unknown> = {},
    config: AxiosRequestConfig = {},
  ): Promise<unknown> {
    const response = await this.caller(Methods.Post, address, params, config);
    return response.data;
  }

  public async patch(address: string, params: Record<string, unknown> = {}): Promise<unknown> {
    const response = await this.caller(Methods.Patch, address, params);
    return response.data;
  }

  public async delete(address: string, params: Record<string, unknown> = {}): Promise<unknown> {
    const response = await this.caller(Methods.Delete, address, params);
    return response.data;
  }

  public setAuth0Token(token: string): void {
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export default Api;
