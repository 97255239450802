import { makeStyles } from '@mui/styles';
import { Color, Font } from '../../app/consts/theme';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 14,
    '&::placeholder': {
      color: '#6C747E',
    },
  },
  headerButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 48,
    width: 48,
    height: 48,
    marginLeft: 24,
    borderRadius: 10,
    border: 'solid 1px',
    borderColor: Color.DARK3,
    backgroundColor: Color.DARK3,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4), 0px 1px 12px rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    '&:active': {
      transform: 'scale(0.9, 0.9)',
    },
    '&:hover': {
      filter: 'brightness(120%)',
    },
  },
  title: {
    flexGrow: 1,
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'none',
    fontFamily: Font.BRAND_MEDIUM,
    fontSize: 48,
    fontWeight: 700,
    letterSpacing: '0.01em',
    color: 'inherit',
    cursor: 'default',
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
  },
  buttonSave: {
    '&&&': {
      width: 155,
      alignSelf: 'flex-end',
    },
  },
});

export default useStyles;
