import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import App from './App';
import theme from './app/consts/theme';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Auth0Provider
        domain={String(process.env.REACT_APP_AUTH0_DOMAIN)}
        clientId={String(process.env.REACT_APP_AUTH0_CLIENT_ID)}
        audience={String(process.env.REACT_APP_AUTH0_AUDIENCE)}
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals';
// void reportWebVitals();
