import React from 'react';
import Api from './Api';
import DictionaryStore from './DictionaryStore/DictionaryStore';
import ProfileStore from './ProfileStore';
import ApplicationStore from './ApplicationStore';
import AccountStore from './AccountStore';

interface IRootStore {
  profileStore: ProfileStore;
  api: Api;
  dictionaryStore: DictionaryStore;
  applicationStore: ApplicationStore;
  accountStore: AccountStore;
}
export class RootStore {
  profileStore;

  api;

  dictionaryStore;

  applicationStore;

  accountStore;

  constructor() {
    this.profileStore = new ProfileStore(this);
    this.api = new Api();
    this.dictionaryStore = new DictionaryStore(this);
    this.applicationStore = new ApplicationStore(this);
    this.accountStore = new AccountStore(this);
  }

  getStores(): IRootStore {
    return {
      profileStore: this.profileStore,
      api: this.api,
      dictionaryStore: this.dictionaryStore,
      applicationStore: this.applicationStore,
      accountStore: this.accountStore,
    };
  }
}

const rootStore: RootStore = new RootStore();

const storesContext = React.createContext(rootStore.getStores());
const useStores = (): IRootStore => React.useContext(storesContext);

export default useStores;
