import cls from 'classnames';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import useStyles from './styles';
import { IInputProps } from './types';

const Input = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & IInputProps>(
  (
    {
      disabled = false,
      variant = 'large',
      type = 'text',
      label = '',
      placeholder,
      id,
      onChange,
      className,
      children,
      fullWidth,
      ...rest
    },
    forwardedRef,
  ) => {
    const classes = useStyles();

    return (
      <div
        className={cls(className, classes.layout, classes[variant], {
          [classes.fullWidth]: fullWidth,
        })}
      >
        {label && (
          <label className={classes.label} id={`${id}-label`} htmlFor={`${id}-input`}>
            {label}
          </label>
        )}
        <input
          className={classes.input}
          id={`${id}-input`}
          type={type}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          ref={forwardedRef}
          {...rest}
        />
        <>{!!children && children}</>
      </div>
    );
  },
);

export default Input;
