import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import React, { memo, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useStores from '../../../../../app/store/RootStore';
import { QuestionIcon, TrashIcon } from '../../../../../assets/icons';
import { useConfirmDialog } from '../../../../../components/ConfirmDialog';
import IconButton from '../../../../../components/IconButton';
import Input from '../../../../../components/Input';
import PageLayout from '../../../../../components/PageLayout';
import PageLoader from '../../../../../components/PageLoader';
import Switch from '../../../../../components/Switch';
import Tooltip from '../../../../../components/Tooltip';
import useStyles from '../../styles';
import { IViewApplication } from '../../types';

interface IProps {
  create: boolean;
  showLoader: boolean;
  setApplication: (state: any) => any;
  application: IViewApplication;
}
const General: React.FC<IProps> = (props) => {
  const { showLoader, setApplication, application, create } = props;
  const classes = useStyles();
  const { getConfirmation } = useConfirmDialog();
  const { applicationStore } = useStores();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeSwitch = (e: SyntheticEvent<HTMLInputElement, Event>) => {
    setApplication((prevState: any) => {
      const target = e.target as HTMLInputElement;
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          [target.id]: target.checked,
        },
      };
    });
  };

  const handleClickDelete = () => {
    getConfirmation({
      title: <FormattedMessage id="deleteApplicationQuestion" />,
      acceptText: <FormattedMessage id="deleteApplication" />,
      declineText: <FormattedMessage id="cancel" />,
      body: <FormattedMessage id="deleteApplicationConfirmBody" />,
      onAccept: async () => {
        if (application.appId) {
          await applicationStore.deleteApplication(application.appId);
          history.push('/');
          enqueueSnackbar(<FormattedMessage id="successDeleteApplication" />);
        }
      },
    });
  };

  return (
    <PageLayout>
      {showLoader ? (
        <PageLoader />
      ) : (
        <>
          <Input
            id="name"
            label="Name"
            value={application.name}
            onChange={(e) =>
              setApplication((prevState: any) => ({
                ...prevState,
                ...{ name: e.target.value },
              }))
            }
            placeholder="New chat"
            autoFocus
          />
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <div className={classes.section}>
                <FormattedMessage id="general" />
              </div>

              <Grid className={classes.sectionItem} container spacing={2} direction="row" alignItems="center">
                <Grid item>
                  <Switch
                    id="accessUnregisteredUsers"
                    checked={application.settings.accessUnregisteredUsers}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
                <Grid item>
                  <FormattedMessage id="accessUnregisteredUsers" />
                </Grid>
                <Grid item />
              </Grid>

              <Grid className={classes.sectionItem} container spacing={2} direction="row" alignItems="center">
                <Grid item>
                  <Switch
                    id="accessChatGuest"
                    checked={application.settings.accessChatGuest}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
                <Grid item>
                  <FormattedMessage id="accessChatGuest" />
                </Grid>
                <Grid item className={classes.helpIcon}>
                  <Tooltip title={<FormattedMessage id="accessChatGuestHelp" />}>
                    <QuestionIcon />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid className={classes.sectionItem} container spacing={2} direction="row" alignItems="center">
                <Grid item>
                  <Switch
                    id="accessRegister"
                    checked={application.settings.accessRegister}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
                <Grid item>
                  <FormattedMessage id="accessRegister" />
                </Grid>
                <Grid item className={classes.helpIcon}>
                  <Tooltip title={<FormattedMessage id="accessRegisterHelp" />}>
                    <QuestionIcon />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <div className={classes.section}>
                <FormattedMessage id="accessToSection" />
              </div>
              <Grid className={classes.sectionItem} container spacing={2} direction="row" alignItems="center">
                <Grid item>
                  <Switch
                    id="displayDirect"
                    checked={application.settings.displayDirect}
                    onChange={(e) => handleChangeSwitch(e)}
                  />
                </Grid>
                <Grid item>
                  <FormattedMessage id="displayDirect" />
                </Grid>
                <Grid item className={classes.helpIcon}>
                  <Tooltip title={<FormattedMessage id="displayDirectHelp" />}>
                    <QuestionIcon />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid className={classes.sectionItem} container spacing={2} direction="row" alignItems="center">
                <Grid item>
                  <Switch
                    id="displayUserList"
                    checked={application.settings.displayUserList}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
                <Grid item>
                  <FormattedMessage id="displayUserList" />
                </Grid>
                <Grid item className={classes.helpIcon}>
                  <Tooltip title={<FormattedMessage id="displayUserListHelp" />}>
                    <QuestionIcon />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid className={classes.sectionItem} container spacing={2} direction="row" alignItems="center">
                <Grid item>
                  <Switch
                    id="displayChannelList"
                    checked={application.settings.displayChannelList}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
                <Grid item>
                  <FormattedMessage id="displayChannelList" />
                </Grid>
                <Grid item className={classes.helpIcon}>
                  <Tooltip title={<FormattedMessage id="displayChannelListHelp" />}>
                    <QuestionIcon />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid className={classes.sectionItem} container spacing={2} direction="row" alignItems="center">
                <Grid item>
                  <Switch
                    id="displayHeader"
                    checked={application.settings.displayHeader}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
                <Grid item>
                  <FormattedMessage id="displayHeader" />
                </Grid>
                <Grid item className={classes.helpIcon}>
                  <Tooltip title={<FormattedMessage id="displayHeaderHelp" />}>
                    <QuestionIcon />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid className={classes.sectionItem} container spacing={2} direction="row" alignItems="center">
                <Grid item>
                  <Switch
                    id="displaySystemMessages"
                    checked={application.settings.displaySystemMessages}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
                <Grid item>
                  <FormattedMessage id="displaySystemMessages" />
                </Grid>
                <Grid item className={classes.helpIcon}>
                  <Tooltip title={<FormattedMessage id="displaySystemMessagesHelp" />}>
                    <QuestionIcon />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!create && (
            <Grid container style={{ height: '100%' }} justifyItems="flex-start" alignItems="flex-end">
              <Grid item>
                <IconButton
                  id="delete-application"
                  variant="danger"
                  onClick={handleClickDelete}
                  text={<FormattedMessage id="deleteApplication" />}
                >
                  <TrashIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default memo(General);
