import { makeStyles } from '@mui/styles';
import { Color } from '../../app/consts/theme';

export default makeStyles({
  layout: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    padding: '12px 16px',
    margin: '0',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
    outline: 'none',
    background: 'transparent',
    border: 'none',
    transition: 'all 0.3s',
    borderRadius: 10,

    '&:focus:not([disabled])': {
      background: 'rgba(255, 255, 255, 0.1)',
    },

    '&:hover:not([disabled])': {
      background: 'rgba(255, 255, 255, 0.1)',
    },

    '& + &': {
      marginLeft: 12,
    },

    '& > svg:first-of-type': {
      width: 18,
      height: 18,
    },
  },
  title: {
    paddingLeft: 10,
  },
  defaultColor: {
    color: Color.WHITE,
  },
  dangerColor: {
    color: Color.RED0,
  },
});
