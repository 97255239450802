import React, { FunctionComponent, useCallback } from 'react';
import Button from '../Button';
import Dialog from '../Dialog';
import useStyles from './styles';
import { TDialogType } from './types';

interface IDialogConfig {
  title: string | React.ReactElement;
  acceptText?: string | React.ReactElement;
  declineText?: string | React.ReactElement;
  body: string | React.ReactElement;
  type?: TDialogType;
  onAccept(): void;
  onDecline?(): void;
}

interface IDialogContext {
  openDialog(config: IDialogConfig): void;
}

const ConfirmDialogContext = React.createContext<Partial<IDialogContext>>({});

const ConfirmDialogProvider: FunctionComponent = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState<IDialogConfig | null>(null);
  const classes = useStyles();

  const openDialog = (config: IDialogConfig) => {
    setDialogOpen(true);
    setDialogConfig(config);
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(null);
  };

  const onConfirm = useCallback(() => {
    resetDialog();
    dialogConfig?.onAccept();
  }, [dialogConfig]);

  const onDismiss = useCallback(() => {
    resetDialog();
    dialogConfig?.onDecline?.();
  }, [dialogConfig]);

  const renderDialog = () => {
    if (!dialogConfig || !dialogOpen) {
      return null;
    }

    const { title, body, declineText, acceptText } = dialogConfig;

    return (
      <Dialog
        canClose={false}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <div className={classes.layout}>
          <div className={classes.title}>{title}</div>
          <div className={classes.message}>{body}</div>
          <div className={classes.buttons}>
            <Button type="button" variant="cancel" onClick={onDismiss}>
              {declineText || 'Cancel'}
            </Button>
            <Button type="button" variant="primary" onClick={onConfirm}>
              {acceptText || 'Ok'}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <ConfirmDialogContext.Provider value={{ openDialog }}>
      {renderDialog()}
      {children}
    </ConfirmDialogContext.Provider>
  );
};

const useConfirmDialog = (): { getConfirmation: IDialogContext['openDialog'] } => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { openDialog: getConfirmation } = React.useContext(ConfirmDialogContext) as IDialogContext;

  return { getConfirmation };
};

export { ConfirmDialogProvider, useConfirmDialog };
