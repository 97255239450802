import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import LinearProgress from '@mui/material/LinearProgress';
import { observer } from 'mobx-react-lite';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { SnackbarUtilsConfigurator } from 'utils/SnackbarUtils';
import './App.css';
import { IRoute } from './app/consts/types';
import LOCALE_DATA from './app/locales';
import routes from './app/routes';
import useStores from './app/store/RootStore';
import { ConfirmDialogProvider } from './components/ConfirmDialog';
import Header from './components/Header';
import Layout from './components/Layout';

const styles = {
  layout: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    maxWidth: 1170,
    flexGrow: 1,
  },
};

const App: React.FC = observer(() => {
  const { getAccessTokenSilently } = useAuth0();
  const { api, profileStore } = useStores();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      api.setAuth0Token(token);
      profileStore.setAuth0Token(token);
      profileStore.setAppReady();
    })();
  }, []);

  if (!profileStore.appReady) return <div>Loading...</div>;

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <IntlProvider locale="en" messages={LOCALE_DATA.en}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <SnackbarUtilsConfigurator />
            <ConfirmDialogProvider>
              <Header />
              <Layout>
                <Switch>
                  {routes.map((route: IRoute, idx) => {
                    const { exact = true, path } = route;

                    return (
                      <Route
                        exact={exact}
                        key={idx}
                        path={path}
                        render={(props) => (
                          <>
                            <div style={styles.layout}>
                              <div style={styles.content}>
                                {/* @ts-ignore */}
                                <route.component {...props} />
                              </div>
                            </div>
                          </>
                        )}
                      />
                    );
                  })}
                </Switch>
              </Layout>
            </ConfirmDialogProvider>
          </SnackbarProvider>
        </IntlProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
});

export default withAuthenticationRequired(App, {
  onRedirecting: () => <LinearProgress style={{ backgroundColor: '#A050C4' }} />,
});
