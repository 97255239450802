import TooltipMaterial from '@mui/material/Tooltip';
import React from 'react';
import useStyles from './styles';

export interface IProps {
  title: string | React.ReactElement;
  children: any;
}

const Tooltip: React.FC<IProps> = ({ children, title, ...props }) => {
  const classes = useStyles();

  return (
    <TooltipMaterial title={title} {...props} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
      {children}
    </TooltipMaterial>
  );
};

export default Tooltip;
