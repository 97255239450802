import { makeStyles } from '@mui/styles';

export default makeStyles({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px 0px',
    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25))',
    minHeight: 500,
    background: '#303339',
    borderRadius: 10,
    border: 'solid 1px',
    borderColor: 'rgba(255, 255, 255, 0.1)',

    '& div': {
      boxSizing: 'border-box',
      lineHeight: '44px',
      width: '100%',
      paddingLeft: 20,
      cursor: 'pointer',
      userSelect: 'none',
      transition: 'all 0.2s ease-in-out',

      '&:not([aria-disabled="true"])': {
        '&:hover': {
          background: '#4F5256',
        },
      },
    },
  },

  itemActive: {
    background: '#5E6165',
  },
  itemDisabled: {
    color: '#5E6165',
  },
});
