import React from 'react';
import Loader from '../Loader';
import useStyles from './styles';

export interface IIconButtonProps extends React.ComponentPropsWithRef<'button'> {
  id: string;
  loading?: boolean;
  text?: string | React.ReactElement;
  variant?: 'default' | 'danger';
}

const IconButton = React.forwardRef<HTMLButtonElement, IIconButtonProps>(
  ({ id, text = '', variant = 'default', loading = undefined, children, ...restProps }, forwardedRef) => {
    const classes = useStyles();

    return (
      <button type="button" className={classes.layout} id={`${id}-icon-button`} ref={forwardedRef} {...restProps}>
        {loading ? <Loader /> : children}
        {text && (
          <div className={`${classes.title} ${variant === 'default' ? classes.defaultColor : classes.dangerColor}`}>
            {text}
          </div>
        )}
      </button>
    );
  },
);

export default IconButton;
