export enum RoleTypesEnum {
  Guest = 'guest',
  User = 'user',
  Moderator = 'moderator',
}

export interface IApplication {
  id: number;
  name: string;
  appId: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  meta: unknown;
  settings: IApplicationSettings;
}

export interface IApplicationSettings {
  accessUnregisteredUsers: boolean;
  accessChatGuest: boolean;
  accessRegister: boolean;
  displayDirect: boolean;
  displayUserList: boolean;
  displayChannelList: boolean;
  displaySystemMessages: boolean;
  displayHeader: boolean;
}

export interface ICreateApplication {
  name?: string;
  settings: IApplicationSettings;
}

export interface IUpdateApplication {
  name?: string;
  settings?: IApplicationSettings;
}

export interface IApplicationUser {
  id: number;
  username: string;
  displayName: string;
  avatarUrl: string | null;
  role: RoleTypesEnum;
}

export interface IGetApplicationUsers {
  data: IApplicationUser[];
}

export interface IGetApplicationUsersPagination {
  offset: number;
  hasNext: boolean;
  total: number;
  limit: number;
}

export interface IChangeApplicationUserRole {
  role: RoleTypesEnum;
}
