import { EmbedBundleLinksType } from 'app/store/DictionaryStore/types';
import useStores from 'app/store/RootStore';
import { CopyIcon } from 'assets/icons';
import PageLoader from 'components/PageLoader';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { IEmbedProps } from 'pages/Applications/View/components/Embed/types';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useStyles from './styles';

const Embed: FC<IEmbedProps> = ({ appId = '' }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { dictionaryStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [jsBundleLink, setJsBundleLink] = useState<EmbedBundleLinksType>();
  const div = `<div id="livedigital-chat" data-appId="${appId || ''}" style="height: 100vh;" />
<script async src="${jsBundleLink?.value || ''}"></script>`;

  useEffect(() => {
    (async () => {
      const jsBundle: EmbedBundleLinksType = await dictionaryStore.getEmbedBundleLinks();
      setJsBundleLink(jsBundle);
      setLoading(false);
    })();
  }, []);

  const handleCopy = async (value: string) => {
    await navigator.clipboard.writeText(value);
    enqueueSnackbar(intl.formatMessage({ id: 'copied' }));
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <p>
            1. Copy the following script and insert it into your website HTML source code between{' '}
            <strong>{'<head>'}</strong> tags.
          </p>
          <div className={classes.textareaContainer}>
            <textarea
              className={classes.textarea}
              rows={3}
              cols={30}
              readOnly
              spellCheck={false}
              name="text"
              value={div}
            />
            <CopyIcon onClick={() => handleCopy(div)} className={classes.clipboard} />
          </div>

          <p>This code must be inserted into every page where you want to display this chat.</p>
        </>
      )}
    </>
  );
};

export default observer(Embed);
