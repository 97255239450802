import { makeStyles } from '@mui/styles';
import { Color } from '../../app/consts/theme';

export default makeStyles({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
  },
  message: {
    marginTop: 8,
    marginBottom: 24,
    fontSize: 16,
    lineHeight: '20px',
    color: Color.WHITE3,
  },
  title: {
    fontSize: 20,
    lineHeight: '24px',
    color: Color.WHITE,
  },
});
