import { makeStyles } from '@mui/styles';
import { Color } from '../../app/consts/theme';

export default makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    transition: 'all 0.3s',
    outline: 'none',
    cursor: 'pointer',
    border: '1px solid transparent',
    overflow: 'hidden',
    borderRadius: 5,
    fontFamily: 'PTRootUIWebRegular',
  },
  large: {
    width: 344,
    height: 48,
    minHeight: 48,
  },
  medium: {
    width: 240,
    height: 48,
    minHeight: 48,
  },
  fullWidth: {
    width: '100%',
  },
  primary: {
    color: '#000000',
    background: '#ffffff',
    boxShadow: '0px 4px 16px rgba(255, 255, 255, 0.6), 0px 2px 2px rgba(255, 255, 255, 0.5)',
    '&:disabled': {
      cursor: 'default',
      color: '#c5c5c5',
      background: '#ffffff',
    },
    '&:not([disabled])': {
      '&:hover': {
        background: '#e7e7e7',
      },
      '&:focus': {
        background: '#d3d3d3',
      },
    },
  },
  secondary: {
    color: '#ffffff',
    border: '1px solid #ffffff',
    background: 'none',
    '&:disabled': {
      color: '#c5c5c5',
      borderColor: '#c5c5c5',
    },
    '&:not([disabled])': {
      '&:hover': {
        color: '#000000',
        background: '#ffffff',
      },
      '&:focus': {
        color: '#000000',
        background: '#e7e7e7',
      },
    },
  },
  attention: {
    background: '#ffa53b',
    color: '#ffffff',
    '&:disabled': {
      color: '#C5C5C5',
      background: '#ffffff',
    },
    '&:not([disabled])': {
      '&:hover': {
        background: '#ed8a15',
      },
      '&:focus': {
        background: '#cd7916',
      },
    },
  },
  cancel: {
    marginLeft: 8,
    padding: '0 16px',
    appearance: 'none',
    color: Color.WHITE,
    background: Color.DARK5,
    border: 'solid white 1px',
    borderRadius: 4,
    fontSize: '12.5px',
    lineHeight: '32px',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
  },
});
