import React, { FunctionComponent, forwardRef } from 'react';
import { IGridProps } from './types';
import useStyles from './styles';
import GridHeader from './Header';

const Grid: FunctionComponent<IGridProps> = forwardRef<HTMLDivElement, IGridProps>(
  ({ children, title, showButtons, onFilter, onCreate }, cardsRef) => {
    const classes = useStyles();

    return (
      <div className={classes.layout}>
        <GridHeader title={title} showButtons={showButtons} onFilter={onFilter} onCreate={onCreate} />
        <div className={classes.cards} ref={cardsRef}>
          {children}
        </div>
      </div>
    );
  },
);

export default Grid;
