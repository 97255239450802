import { makeStyles } from '@mui/styles';
import { Color, ZIndex } from '../../app/consts/theme';

const NAVLINK_UNDERLINE_MARGIN = 4;

const useStyles = makeStyles({
  layout: {
    position: 'relative',
    zIndex: ZIndex.HEADER,
    gridArea: 'header',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 32,
    height: 50,
    backgroundColor: Color.DARK1,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.15)',
  },
  headerContent: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: 1170,
  },
  navBar: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    top: NAVLINK_UNDERLINE_MARGIN,
    fontSize: 14,
    fontWeight: 700,
    '& > a': {
      display: 'block',
      marginRight: 24,
      paddingBottom: NAVLINK_UNDERLINE_MARGIN,
      borderBottom: 'solid 2px',
      borderBottomColor: 'transparent',
      color: 'inherit',
      textDecoration: 'none',
    },
  },
});

export default useStyles;
