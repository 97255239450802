import { makeStyles } from '@mui/styles';
import { Color } from '../../app/consts/theme';

const useStyles = makeStyles({
  arrow: {
    color: Color.WHITE,
  },
  tooltip: {
    backgroundColor: Color.WHITE,
    color: '#333333',
    fontFamily: 'PTRootUIWebRegular',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)',
    fontSize: 12,
    borderRadius: 8,
  },
});

export default useStyles;
