import SwitchMaterial, { SwitchProps } from '@mui/material/Switch';
import React from 'react';
import useStyles from './styles';

const Switch: React.FC<SwitchProps> = (props) => {
  const classes = useStyles();

  return (
    <SwitchMaterial
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export default Switch;
