import React, { FunctionComponent, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Popup from '../../Popup';
import useStyles from './styles/userMenu';

const UserMenu: FunctionComponent = (props) => {
  const { logout, user } = useAuth0();
  const classes = useStyles();
  const history = useHistory();

  const handleLogoutClick = useCallback(() => {
    if (user) {
      logout();
    } else {
      window.location.reload();
    }
  }, []);

  const handleSettingsClick = useCallback(() => {
    history.push('/profile');
  }, []);

  return (
    <Popup {...props}>
      <div className={classes.menu}>
        <div
          className={classes.menuItem}
          onClick={handleSettingsClick}
          onKeyDown={handleSettingsClick}
          role="button"
          tabIndex={0}
        >
          <FormattedMessage id="settings" />
        </div>
        <div
          className={classes.menuItem}
          style={{ color: 'red' }}
          onClick={handleLogoutClick}
          onKeyDown={handleLogoutClick}
          role="button"
          tabIndex={0}
        >
          <FormattedMessage id="logout" />
        </div>
      </div>
    </Popup>
  );
};

export default UserMenu;
