import React, { FunctionComponent } from 'react';
import ReactLoaderSpinner, { LoaderProps } from 'react-loader-spinner';

interface ILoaderProps extends Omit<LoaderProps, 'type'> {
  enabled?: boolean;
  type?: LoaderProps['type'];
}

const Loader: FunctionComponent<ILoaderProps> = ({ enabled = true, type = 'TailSpin', ...rest }) => {
  if (!enabled) {
    return null;
  }

  return <ReactLoaderSpinner type={type} color="#99A2AD" height={24} width={24} {...rest} />;
};

Loader.defaultProps = {
  enabled: true,
};

export default Loader;
