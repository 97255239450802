import { action, makeAutoObservable, observable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { RootStore } from './RootStore';

class ProfileStore {
  private readonly rootStore;

  @observable
  public auth0Token = '';

  @observable
  public appReady = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setAuth0Token(token: string): void {
    this.auth0Token = token;
  }

  @action
  setAppReady(): void {
    this.appReady = true;
  }
}

export default ProfileStore;
