import { action, makeAutoObservable, observable } from 'mobx';
import { RootStore } from '../RootStore';
import {
  IApplication,
  IApplicationUser,
  IChangeApplicationUserRole,
  ICreateApplication,
  IGetApplicationUsers,
  IGetApplicationUsersPagination,
  IUpdateApplication,
} from './types';

class ApplicationStore {
  rootStore;

  @observable applications: IApplication[] | [] = [];

  @observable applicationUsers: IApplicationUser[] | [] = [];

  @observable applicationUsersPagination: IGetApplicationUsersPagination = {
    offset: 0,
    hasNext: false,
    limit: 10,
    total: 0,
  };

  @observable loading = true;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action async getApplications(): Promise<void> {
    this.loading = true;
    const response = (await this.rootStore.api.get('/customers/applications')) as IApplication[];
    this.setApplications(response);
    this.loading = false;
  }

  @action async getApplication(id: string): Promise<IApplication> {
    return (await this.rootStore.api.get(`/customers/applications/${id}`)) as IApplication;
  }

  @action async createApplication(data: ICreateApplication): Promise<void> {
    await this.rootStore.api.post('/customers/applications/create', { ...data });
    await this.getApplications();
  }

  @action async updateApplication(id: string, data: IUpdateApplication): Promise<void> {
    await this.rootStore.api.patch(`/customers/applications/${id}`, { ...data });
    await this.getApplications();
  }

  @action async deleteApplication(id: string): Promise<void> {
    await this.rootStore.api.delete(`/customers/applications/${id}`);
    await this.getApplications();
  }

  @action async getApplicationUsers(
    id: string,
    offset?: number,
    limit?: number,
    search?: string,
    append?: boolean,
  ): Promise<void> {
    const { data, ...pagination } = (await this.rootStore.api.get(`/customers/applications/${id}/users`, {
      offset,
      limit,
      search: search?.trim(),
    })) as IGetApplicationUsers & IGetApplicationUsersPagination;

    if (append) {
      this.setApplicationUsers([...this.applicationUsers, ...data]);
    } else {
      this.setApplicationUsers(data);
    }

    this.setApplicationUsersPagination(pagination);
  }

  @action async changeApplicationUserRole(id: string, userId: number, data: IChangeApplicationUserRole): Promise<void> {
    await this.rootStore.api.patch(`/customers/applications/${id}/users/${userId}`, { ...data });
    const userIndex = this.applicationUsers.findIndex((user) => +user.id === +userId);
    if (userIndex !== -1) {
      this.applicationUsers[userIndex].role = data.role;
    }
  }

  @action async removeApplicationUser(id: string, userId: number): Promise<void> {
    await this.rootStore.api.delete(`/customers/applications/${id}/users/${userId}`);
    const userIndex = this.applicationUsers.findIndex((user) => +user.id === +userId);
    if (userIndex !== -1) {
      this.applicationUsers.splice(userIndex, 1);
    }
  }

  @action setApplicationUsers(data: IApplicationUser[] | []): void {
    this.applicationUsers = data;
  }

  @action setApplicationUsersPagination(data: IGetApplicationUsersPagination): void {
    this.applicationUsersPagination = data;
  }

  @action setApplications(data: IApplication[] | []): void {
    this.applications = data;
  }
}

export default ApplicationStore;
