import { action, makeAutoObservable, observable } from 'mobx';
import { Options } from '../../../controls/Select/Select';
import { RootStore } from '../RootStore';
import { DictionaryType, EmbedBundleLinksType } from './types';

class DictionaryStore {
  rootStore;

  @observable roles: DictionaryType = null;

  @observable rolesOptions: Options<string, string> = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action async getRoles(): Promise<void> {
    const response = (await this.rootStore.api.get('/casbin/pre-defined-roles')) as DictionaryType;
    this.setRoles(response);
  }

  @action setRoles(data: DictionaryType): void {
    this.roles = data;
    this.rolesOptions = data?.map((el) => ({ label: el.value || '', value: el.id || '' })) || [];
  }

  @action async getEmbedBundleLinks(): Promise<EmbedBundleLinksType> {
    return (await this.rootStore.api.get(`/settings/js-bundle`)) as EmbedBundleLinksType;
  }
}

export default DictionaryStore;
