import { makeStyles } from '@mui/styles';

export default makeStyles({
  textareaContainer: {
    width: '100%',
  },
  textarea: {
    width: '90%',
    backgroundColor: '#232629',
    color: 'white',
    borderRadius: 10,
    borderColor: 'rgba(255, 255, 255, 0.2)',
    padding: 10,
    resize: 'none',
  },
  clipboard: {
    position: 'relative',
    right: 28,
    top: -40,
    width: 20,
    height: 20,
    cursor: 'pointer',
    opacity: '0.5',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      opacity: '0.7',
    },
  },
});
