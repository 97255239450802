import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import useStyles from './styles';
import UserButton from './UserButton';

const Header: React.FC = (props) => {
  const { user } = useAuth0();

  const classes = useStyles(props);

  return (
    <div className={classes.layout}>
      <div className={classes.headerContent}>
        <div className={classes.navBar}>
          <NavLink to="/">LiveDigital.Chat</NavLink>
        </div>
      </div>
      <UserButton name={user?.name} icon={user?.picture} />
    </div>
  );
};

export default Header;
