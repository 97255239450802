import { makeStyles } from '@mui/styles';
import { Color } from '../../../../../../app/consts/theme';

interface IProps {
  open: boolean;
}

export default makeStyles({
  menu: {
    background: '#3B3E42',
    color: Color.WHITE,
    border: '0.5px solid rgba(0, 0, 0, 0.08)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08)',
    borderRadius: 5,
  },
  menuItem: {
    display: 'block',
    padding: '0 20px',
    height: 40,
    fontSize: '14px',
    lineHeight: '40px',
    textTransform: 'none',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: Color.DARK1,
    },
  },
  button: {
    color: Color.WHITE,
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'capitalize',
    position: 'relative',
    width: 'fit-content',
    padding: '5px 10px',
    borderRadius: 5,
    borderBottomRightRadius: 0,
    boxSizing: 'border-box',
    backgroundColor: ({ open }: IProps): Color.DARK2 | 'inherit' => (open ? Color.DARK2 : 'inherit'),
    cursor: 'pointer',
  },
  arrowDown: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12,
  },
});
