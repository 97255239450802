import React, { FunctionComponent, forwardRef } from 'react';
import { IPageHeaderProps } from './types';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import useStyles from './styles';
import Button from '../Button';

const PageHeader: FunctionComponent<IPageHeaderProps> = forwardRef<HTMLDivElement, IPageHeaderProps>(
  ({ headText, buttonText = '', onSubmit = () => {}, onClose = () => {}, buttonSubmitHidden = false }) => {
    const classes = useStyles();

    const handleCloseClick = () => {
      onClose?.();
    };

    const handleSubmitClick = () => {
      onSubmit?.();
    };

    return (
      <div className={classes.header}>
        <h2 className={classes.title}>{headText}</h2>
        {!buttonSubmitHidden && (
          <Button id="save" size="medium" onClick={handleSubmitClick}>
            {buttonText}
          </Button>
        )}
        <div
          className={classes.headerButton}
          onClick={handleCloseClick}
          onKeyDown={handleCloseClick}
          role="button"
          tabIndex={0}
        >
          <CloseIcon />
        </div>
      </div>
    );
  },
);

export default PageHeader;
