import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useStores from '../../app/store/RootStore';
import Button from '../../components/Button';
import { useConfirmDialog } from '../../components/ConfirmDialog';
import Input from '../../components/Input';
import PageHeader from '../../components/PageHeader';
import PageLayout from '../../components/PageLayout';
import PageSidebar from '../../components/PageSidebar';
import useStyles from './styles';
import { AccountButtonEnum, AccountButtons } from './types';

const Account: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { accountStore } = useStores();
  const { m2m } = accountStore;
  const { user } = useAuth0();

  const [selectedSection, setSelectedSection] = useState(AccountButtonEnum.Profile);
  const { getConfirmation } = useConfirmDialog();

  useEffect(() => {
    if (m2m) {
      return;
    }
    (async () => {
      await accountStore.getM2M();
    })();
  }, []);

  const handleOnClickClose = () => {
    history.push('/');
  };

  const handleGenerateNewToken = () => {
    getConfirmation({
      title: intl.formatMessage({ id: 'generateNewM2mTokenConfirmTitle' }),
      acceptText: intl.formatMessage({ id: 'generateNewM2mTokenConfirmAcceptText' }),
      declineText: intl.formatMessage({ id: 'cancel' }),
      body: intl.formatMessage({ id: 'generateNewM2mTokenConfirmBody' }),
      type: 'confirm',
      onAccept: async () => {
        await accountStore.generateM2M();
      },
    });
  };
  const sideBarItems = AccountButtons.map((item) => ({
    title: item as string,
    onClick: () => setSelectedSection(item),
  }));

  return (
    <div className={classes.layout}>
      <PageHeader
        buttonText={intl.formatMessage({ id: 'save' })}
        buttonSubmitHidden
        headText={intl.formatMessage({ id: 'myAccount' })}
        onClose={handleOnClickClose}
      />

      <div style={{ marginTop: 40 }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <PageSidebar items={sideBarItems} selected={selectedSection} />
          </Grid>

          <Grid item xs={9}>
            <PageLayout>
              <Grid container spacing={3}>
                {selectedSection === AccountButtonEnum.Profile && [
                  <Grid item>
                    <Input id="name" label="Name" value={user?.name} disabled />
                    <Input id="email" type="email" label="E-Mail" value={user?.email} disabled />
                  </Grid>,
                ]}
                {selectedSection === AccountButtonEnum.Integration && [
                  <Grid container item spacing={2}>
                    <Grid item>
                      <Input id="m2m" label="M2M Token" value={m2m} disabled />
                    </Grid>
                    <Grid item>
                      <Button style={{ marginTop: 20 }} size="medium" onClick={handleGenerateNewToken}>
                        <FormattedMessage id="generateNewM2mToken" />
                      </Button>
                    </Grid>
                  </Grid>,
                ]}
              </Grid>
            </PageLayout>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default observer(Account);
