// https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-542863653
import { SnackbarKey, useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import React from 'react';

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg: string): SnackbarKey {
    return this.toast(msg, 'success');
  },
  warning(msg: string): SnackbarKey {
    return this.toast(msg, 'warning');
  },
  info(msg: string): SnackbarKey {
    return this.toast(msg, 'info');
  },
  error(msg: string): SnackbarKey {
    return this.toast(msg, 'error');
  },
  toast(msg: string, variant: VariantType = 'default'): SnackbarKey {
    return useSnackbarRef.enqueueSnackbar(msg, { variant });
  },
};
