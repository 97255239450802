import { IApplicationSettings } from '../../../app/store/ApplicationStore/types';

export interface IProps {
  create?: boolean;
}

export interface IViewApplication {
  name: string;
  appId?: string;
  settings: IApplicationSettings;
}

export enum SideBarItemsEnum {
  'General' = 'General',
  'UserManagement' = 'User management',
  'Integration' = 'Integration',
  'Embed' = 'Embed',
}

export const SideBarItems = Object.values(SideBarItemsEnum);

export const initAppData = {
  name: '',
  appId: '',
  settings: {
    accessUnregisteredUsers: true,
    accessChatGuest: true,
    accessRegister: true,
    displayDirect: false,
    displayUserList: true,
    displayChannelList: true,
    displaySystemMessages: true,
    displayHeader: true,
  },
};
