import { ClassNameMap, makeStyles } from '@mui/styles';
import { ZIndex } from '../../app/consts/theme';
import { IRect } from './types';

const useStyles = (rect: IRect | null): ((props?: unknown) => ClassNameMap<'layout'>) =>
  makeStyles({
    layout: {
      ...rect,
      position: 'absolute',
      zIndex: ZIndex.POPUP,
      visibility: 'hidden',
      margin: '0 auto',
      textAlign: 'left',
      opacity: 0.0,
      transition: 'visibility 0.1s linear, opacity 0.1s linear',
      '&.popup-enter-done': {
        opacity: 1,
        visibility: 'visible',
      },
      '&.popup-exit-done': {
        opacity: 0,
        visibility: 'hidden',
      },
    },
  });

export default useStyles;
