import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import Embed from 'pages/Applications/View/components/Embed';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { IApplication } from '../../../app/store/ApplicationStore/types';
import useStores from '../../../app/store/RootStore';
import { objectToSearchParams, searchParamsToObject } from '../../../app/utils/helper';
import Input from '../../../components/Input';
import PageHeader from '../../../components/PageHeader';
import PageLayout from '../../../components/PageLayout';
import PageSidebar from '../../../components/PageSidebar';
import General from './components/General';
import UsersManagement from './components/UsersManagement';
import useStyles from './styles';
import { initAppData, IProps, IViewApplication, SideBarItems, SideBarItemsEnum } from './types';

const ApplicationView: FunctionComponent<IProps> = ({ create = false }) => {
  const history = useHistory();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { applicationStore } = useStores();
  const { id: appId }: { id: string } = useParams();

  const [loading, setLoading] = useState(true);

  const [application, setApplication] = useState<IViewApplication>(initAppData);

  const searchParams = searchParamsToObject(history.location.search);

  const [selectedSection, setSelectedSection] = useState((searchParams.tab as string) || SideBarItemsEnum.General);

  useEffect(() => {
    if (!appId) {
      return;
    }

    (async () => {
      const updateApplication: IApplication = await applicationStore.getApplication(appId);
      setApplication({
        name: updateApplication.name,
        settings: updateApplication.settings,
        appId: updateApplication.appId,
      });
      setLoading(false);
    })();
  }, [appId]);

  const handleOnClickClose = () => {
    history.push('/');
  };

  const handleClickSubmit = async () => {
    if (create) {
      await applicationStore.createApplication(
        application.name
          ? {
              name: application.name,
              settings: application.settings,
            }
          : {
              settings: application.settings,
            },
      );
    } else {
      await applicationStore.updateApplication(appId, { name: application.name, settings: application.settings });
    }

    history.push('/');
    enqueueSnackbar(intl.formatMessage({ id: create ? 'successCreatedApplication' : 'successUpdatedApplication' }));
  };

  const sideBarItems = SideBarItems.map((item) => ({
    title: item as string,
    onClick: () => {
      setSelectedSection(item);
      history.replace({
        search: objectToSearchParams({
          ...searchParamsToObject(history.location.search),
          tab: item,
        }),
      });
    },
  }));

  return (
    <div className={classes.layout}>
      <PageHeader
        buttonText={intl.formatMessage({ id: create ? 'create' : 'save' })}
        headText={intl.formatMessage({ id: create ? 'createNewApp' : 'appSettings' })}
        onSubmit={handleClickSubmit}
        onClose={handleOnClickClose}
      />

      <div style={{ marginTop: 40 }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <PageSidebar items={sideBarItems} selected={selectedSection} disabled={create} />
          </Grid>

          <Grid item xs={9}>
            {selectedSection === SideBarItemsEnum.General && (
              <General
                showLoader={!create && loading}
                create={create}
                setApplication={setApplication}
                application={application}
              />
            )}

            {selectedSection === SideBarItemsEnum.Integration && (
              <PageLayout>
                <Input id="appId" label="API TOKEN" value={!create ? application.appId : ''} disabled />
              </PageLayout>
            )}

            {selectedSection === SideBarItemsEnum.UserManagement && !create && (
              <PageLayout disablePadding>
                <UsersManagement appId={appId} />
              </PageLayout>
            )}

            {selectedSection === SideBarItemsEnum.Embed && (
              <PageLayout>
                <Embed appId={application.appId} />
              </PageLayout>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default ApplicationView;
