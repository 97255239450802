import React, { FunctionComponent, useRef, useCallback, useEffect } from 'react';
import { CloseIcon } from '../../assets/icons';
import useStyles from './styles';
import { IDialogProps } from './types';

const Dialog: FunctionComponent<IDialogProps> = ({ children, fixedPosition = false, canClose = true, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles({ canClose })();

  const handleOverlayClick = useCallback(
    (event: React.MouseEvent) => {
      if (!canClose) {
        event.stopPropagation();
      }
    },
    [canClose],
  );

  useEffect(() => {
    if (fixedPosition && ref.current) {
      const content = ref.current;
      const { top } = content.getBoundingClientRect();
      Object.assign(content.style, { position: 'absolute', top: `${top}px` });
    }
  }, []);

  return (
    <div className={classes.layout} onMouseDownCapture={handleOverlayClick}>
      <div className={classes.content} ref={ref}>
        {canClose && (
          <div
            id="dialog-close"
            className={classes.closeButton}
            onClick={onClose}
            onKeyDown={onClose}
            role="button"
            tabIndex={0}
          >
            <CloseIcon />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Dialog;
