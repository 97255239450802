import { Avatar, Grid, TableCell } from '@mui/material';
import useStores from 'app/store/RootStore';
import Input from 'components/Input';
import PageLoader from 'components/PageLoader';
import { observer } from 'mobx-react-lite';
import UserManagementMenu from 'pages/Applications/View/components/UsersManagement/UserManagementMenu';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutoSizer, Column, Table, InfiniteLoader, TableCellRenderer, IndexRange } from 'react-virtualized';
import useStyles from './styles';
import { IApplicationUser } from '../../../../../app/store/ApplicationStore/types';

export interface IProps {
  appId: string;
}

const UsersManagement: FC<IProps> = (props) => {
  const { appId } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { applicationStore } = useStores();
  const { applicationUsers: users, applicationUsersPagination: paginationParams } = applicationStore;
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');

  const loadMoreRows = async ({ startIndex, stopIndex }: IndexRange) => {
    if (users.length < paginationParams.total && paginationParams.hasNext) {
      return applicationStore.getApplicationUsers(appId, startIndex, undefined, undefined, true);
    }

    return Promise.resolve();
  };

  useEffect(() => {
    (async () => {
      await applicationStore.getApplicationUsers(appId);
      setLoading(false);
    })();
  }, []);

  const onSearch = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      await applicationStore.getApplicationUsers(appId, undefined, undefined, filter);
    }
  };

  const cellAvatarRenderer: TableCellRenderer = ({ rowData }) => {
    const user = rowData as IApplicationUser;
    return (
      <TableCell component="div" className={classes.tableCell} variant="body">
        <Avatar src={user.avatarUrl ? user.avatarUrl : undefined} alt={user.username}>
          {(user.username || user.displayName)[0].toUpperCase()}
        </Avatar>
      </TableCell>
    );
  };

  const cellNameRenderer: TableCellRenderer = ({ rowData }) => {
    const user = rowData as IApplicationUser;
    return (
      <TableCell component="div" className={classes.tableCell} variant="body">
        {user.displayName}
        {user.username ? ` (${user.username})` : null}
      </TableCell>
    );
  };

  const cellActionsRenderer: TableCellRenderer = ({ rowData }) => {
    const user = rowData as IApplicationUser;
    return (
      <TableCell component="div" style={{ float: 'right' }} className={classes.tableCell} variant="body">
        <UserManagementMenu appId={appId} user={user} />
      </TableCell>
    );
  };

  const columns = [
    {
      width: 90,
      cellRenderer: cellAvatarRenderer,
      dataKey: 'avatar',
      style: { display: 'flex', justifyContent: 'center' },
    },
    {
      width: 400,
      cellRenderer: cellNameRenderer,
      dataKey: 'name',
    },
    { width: 130, cellRenderer: cellActionsRenderer, dataKey: 'actions', style: { marginLeft: 'auto', order: 2 } },
  ];

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Input
        id="search-user-management"
        onChange={(e) => setFilter(e.target.value)}
        className={classes.inputSearch}
        onKeyPress={onSearch}
        placeholder={intl.formatMessage({ id: 'search' })}
      />
      {users.length ? (
        <div className={classes.tableContainer}>
          <InfiniteLoader
            isRowLoaded={({ index }) => !!users[index]}
            threshold={50}
            loadMoreRows={loadMoreRows}
            rowCount={paginationParams.total}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    headerHeight={0}
                    className={classes.root}
                    height={height}
                    width={width}
                    rowGetter={({ index }) => users[index]}
                    rowHeight={52}
                    rowCount={users.length}
                    rowStyle={({ index }) => (index !== -1 && index % 2 === 0 ? { backgroundColor: '#353A40' } : {})}
                    rowClassName={classes.tableRow}
                    gridStyle={{
                      direction: 'inherit',
                    }}
                  >
                    {columns.map((columnProps) => (
                      <Column key={columnProps.dataKey} {...columnProps} />
                    ))}
                  </Table>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      ) : (
        <Grid container alignItems="center" style={{ height: '60%' }} justifyContent="center">
          <Grid item component="p" className={classes.notFound}>
            <FormattedMessage id="noResults" />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default observer(UsersManagement);
