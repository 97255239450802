import { makeStyles } from '@mui/styles';

export default makeStyles({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px 24px',
    height: 476,
    background: '#303339',
    borderRadius: 10,
    border: 'solid 1px',
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  disablePadding: {
    padding: 0,
    height: 516,
  },
});
