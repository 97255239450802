import React, { FunctionComponent } from 'react';
import DropDownButton from '../../DropDownButton';
import useStyles from './styles/userButton';
import UserMenu from './UserMenu';

interface IUserButtonProps {
  name?: string;
  icon?: string;
}

const UserButton: FunctionComponent<IUserButtonProps> = ({ name = '', icon }) => {
  const classes = useStyles({ icon });

  return (
    <div className={classes.userButtonLayout}>
      <DropDownButton menu={<UserMenu />}>
        <div className={classes.user}>
          <div className={classes.userName}>{name}</div>
          <div className={classes.userIcon} />
        </div>
      </DropDownButton>
    </div>
  );
};
export default UserButton;
