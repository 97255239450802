import cls from 'classnames';
import React, { ButtonHTMLAttributes, FunctionComponent } from 'react';
import Loader from '../Loader';
import useStyles from './styles';
import { IButtonProps } from './types';

const Button: FunctionComponent<ButtonHTMLAttributes<HTMLButtonElement> & IButtonProps> = ({
  id,
  onClick = () => undefined,
  children = null,
  isLoading = false,
  variant = 'primary',
  size = 'large',
  fullWidth,
  ...rest
}) => {
  const classes = useStyles();
  const btnClasses = cls(classes.root, classes[variant], classes[size], {
    [classes.fullWidth]: fullWidth,
  });

  return (
    <button type="button" className={btnClasses} id={`${id}-button`} onClick={onClick} {...rest}>
      {isLoading ? <Loader /> : children}
    </button>
  );
};

export default Button;
