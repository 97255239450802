import { makeStyles } from '@mui/styles';

export default makeStyles({
  layout: {
    color: '#FFFFFF',
    margin: 0,
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  fullWidth: {
    width: '100%',
  },
  label: {
    marginBottom: 4,
    display: 'block',
  },
  input: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 8px',
    background: 'none',
    outline: 'none',
    transition: 'all 0.3s',
    fontFamily: 'PTRootUIWebRegular',
    border: '1px solid #b8b6c2',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: '100%',
    height: 48,
    '&:hover': {
      border: '1px solid #e0e0e0',
    },

    '&:focus': {
      border: '1px solid #e0e0e0',
    },

    '&:disabled': {
      color: '#c5c5c5',
    },
  },
  large: {
    width: 344,
  },
  medium: {
    width: 240,
  },
});
