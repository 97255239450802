import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

const PageLoader: React.FC = () => (
  <Grid container alignItems="center" style={{ height: '100%' }} justifyContent="center">
    <Grid item component={CircularProgress} />
  </Grid>
);

export default PageLoader;
