import { action, makeAutoObservable, observable } from 'mobx';
import { RootStore } from '../RootStore';
import { IM2M } from './types';

class AccountStore {
  private readonly rootStore;

  @observable
  public auth0Token = '';

  @observable
  public loading = true;

  @observable
  public appReady = false;

  @observable
  public m2m = '';

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action async getM2M(): Promise<void> {
    this.loading = true;
    const response = (await this.rootStore.api.get('/customers/m2m')) as IM2M;
    this.setM2M(response);
    this.loading = false;
  }

  @action async generateM2M(): Promise<void> {
    this.loading = true;
    const response = (await this.rootStore.api.post('/customers/m2m/generate')) as IM2M;
    this.setM2M(response);
    this.loading = false;
  }

  @action
  setAuth0Token(token: string): void {
    this.auth0Token = token;
  }

  @action
  setM2M(m2m: IM2M): void {
    this.m2m = m2m.token;
  }

  @action
  setAppReady(): void {
    this.appReady = true;
  }
}

export default AccountStore;
