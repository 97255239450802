import React, { FunctionComponent, useCallback } from 'react';
import { IProps } from './types';
import useStyles from './styles';

const Application: FunctionComponent<IProps> = ({ name, id, onOpen }) => {
  const classes = useStyles();

  const handleCardClick = useCallback(() => {
    onOpen(id);
  }, [onOpen]);

  return (
    <div
      id={id}
      className={classes.applicationCard}
      data-qa-space-name={name}
      onClick={handleCardClick}
      onKeyDown={handleCardClick}
      role="button"
      tabIndex={0}
    >
      <div className={classes.divider} />
      <div className={classes.cardName} id="space-name">
        {name}
      </div>
    </div>
  );
};

export default Application;
