import { makeStyles } from '@mui/styles';

export default makeStyles({
  root: {
    width: 37,
    height: 24,
    padding: 0,
    borderRadius: 12,
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(13px)',
      color: 'white',
      '& + $track': {
        background: 'radial-gradient(100% 167.95% at 0% 50%, #00E0FF 0%, #BF51F2 74.27%, #F65FC7 99.99%, #F960C5 100%)',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      border: '1px solid #31333A',
      margin: -1,
    },
  },
  thumb: {
    width: 20,
    height: 20,
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  track: {
    borderRadius: 10,
    backgroundColor: '#C0C0C0',
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
});
