import { makeStyles } from '@mui/styles';
import { Color } from '../../../../app/consts/theme';

export default makeStyles({
  menu: {
    width: 'max-content',
    borderRadius: 5,
    border: '0.5px solid rgba(0, 0, 0, 0.08)',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4), 0px 1px 12px rgba(0, 0, 0, 0.3)',
    backgroundColor: Color.DARK2,
    textAlign: 'left',
  },
  menuItem: {
    display: 'block',
    padding: '0 20px',
    height: 40,
    lineHeight: '40px',
    textTransform: 'none',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: Color.DARK1,
    },
  },
});
