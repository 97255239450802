import { createTheme } from '@mui/material/styles';

export enum Color {
  BLACK = '#000000',
  DARK0 = '#232629',
  DARK1 = '#565A60',
  DARK2 = '#3B3E42',
  DARK3 = '#353A41',
  DARK4 = '#6D747D',
  DARK5 = '#303339',
  DARK6 = '#222629',
  RED0 = '#FC2F2F',
  WHITE = '#FFFFFF',
  WHITE0 = 'rgba(255,255,255,0.1)',
  WHITE1 = 'rgba(255,255,255,0.7)',
  WHITE2 = '#B8B6C2',
  WHITE3 = '#C5C5C5',
}

export enum ZIndex {
  ROOM_FOREGROUND = 5,
  SLOT_VIDEO = 1,
  SLOT_SOUND = 2,
  SLOT_OVERLAY = 4,
  SLOT_SHARE_ICON = 5,
  HEADER = 10,
  OVERLAY = 40,
  DIALOG = 50,
  POPUP = 100,
}
export enum Font {
  BRAND = 'PTRootUIWebRegular',
  BRAND_BOLD = 'PTRootUIWebBold',
  BRAND_LIGHT = 'PTRootUIWebLight',
  BRAND_MEDIUM = 'PTRootUIWebMedium',
}

const palette = {
  primary: {
    main: '#6D747D',
  },
  secondary: {
    main: '#D50000',
  },
  text: {},
  type: 'dark',
};

const theme = {
  palette,

  overrides: {},
};

export default createTheme(theme);
