import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Loader from 'components/Loader';
import Grid from '../../components/Grid';
import ApplicationCard from '../../components/Card/Application';
import useStores from '../../app/store/RootStore';

const Applications: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const [nameFilter, setNameFilter] = useState('');
  const { applicationStore } = useStores();
  const { applications, loading } = applicationStore;

  useEffect(() => {
    if (!applications.length) {
      (async () => {
        await applicationStore.getApplications();
      })();
    }
  }, []);

  const cards = useMemo(
    () => applications.filter(({ name }) => name.toLocaleUpperCase().includes(nameFilter)),
    [applications, nameFilter],
  );

  const handleFilterCards = useCallback((name: string) => {
    setNameFilter(name.toLocaleUpperCase());
  }, []);

  const handleCreateApplication = useCallback(() => {
    history.push('/applications/create');
  }, []);

  const handleOpenApplication = useCallback((id: number) => {
    history.push(`/applications/${id}`);
  }, []);

  return (
    <Grid
      title={intl.formatMessage({
        id: 'myApps',
      })}
      showButtons
      onFilter={handleFilterCards}
      onCreate={handleCreateApplication}
    >
      {loading && <Loader />}

      {!loading &&
        (cards.length > 0 || nameFilter) &&
        cards.map(({ name, id }) => (
          <ApplicationCard id={id.toString()} key={id} name={name} onOpen={() => handleOpenApplication(id)} />
        ))}

      {!loading && !cards.length && (
        <ApplicationCard
          key={0}
          id="empty"
          name={<FormattedMessage id="createNewApp" />}
          onOpen={handleCreateApplication}
        />
      )}
    </Grid>
  );
};

export default observer(Applications);
