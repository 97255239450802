import React, { FunctionComponent, useRef, useEffect, useCallback, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { IPopupProps, IRect, TAlignment } from './types';
import Portal from '../Portal';
import useStyles from './styles';

const SNACKBAR_OFFSET = 15;

const calcPosition = (popup: HTMLElement | null, anchor: HTMLElement | null, alignment: TAlignment): IRect | null => {
  if (!popup) {
    return null;
  }
  /* TODO: alignment option */
  const popupRect = popup.getBoundingClientRect();
  if (anchor) {
    const anchorRect = anchor.getBoundingClientRect();
    if (alignment === 'center-bottom') {
      const left = anchorRect.left + (anchorRect.width - popupRect.width) / 2;
      const top = anchorRect.top + anchorRect.height / 2;

      return { top, left };
    }
    const left = alignment === 'right' ? anchorRect.right - popupRect.width : anchorRect.left;
    const top = anchorRect.bottom;

    return { left, top };
  }

  if (popup.parentElement) {
    const parent = popup.parentElement;
    const viewRect = parent?.getBoundingClientRect();
    if (alignment === 'top' || alignment === 'bottom') {
      // no anchor element and has alignment - this is snackbar
      const top = alignment === 'top' ? SNACKBAR_OFFSET : viewRect.height - popupRect.height - SNACKBAR_OFFSET;
      const left = 0;
      const right = 0;
      const { width } = popupRect;

      return {
        left,
        top,
        right,
        width, // makes it centered even with window resize
      };
    }

    if (alignment === 'left-bottom') {
      const top = viewRect.height - popupRect.height - SNACKBAR_OFFSET;
      const left = SNACKBAR_OFFSET;
      const { width } = popupRect;

      return {
        left,
        top,
        width, // makes it centered even with window resize
      };
    }
  }
  return null;
};

const Popup: FunctionComponent<IPopupProps> = ({
  children,
  isVisible,
  onClose,
  anchor,
  hostElementId,
  position = null,
  alignment = 'right',
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [popupDimensions, setPopupDimensions] = useState<IRect | null>(null);
  const classes = useStyles(popupDimensions)();

  useEffect(() => {
    if (isVisible) {
      setPopupDimensions(position ?? calcPosition(ref.current, anchor?.current ?? null, alignment));
    }
  }, [isVisible]);

  const handleMount = useCallback(
    (el) => {
      ref.current = el;
      setPopupDimensions(position ?? calcPosition(ref.current, anchor?.current ?? null, alignment));
    },
    [alignment],
  );

  return (
    <Portal hostElementId={hostElementId}>
      <CSSTransition in={isVisible} timeout={0} classNames="popup" nodeRef={ref}>
        <div
          ref={handleMount}
          // isVisible={isVisible}
          className={`${classes.layout} popup`}
          onClick={() => {
            onClose?.();
          }}
          onKeyDown={() => {
            onClose?.();
          }}
          role="button"
          tabIndex={0}
        >
          {children}
        </div>
      </CSSTransition>
    </Portal>
  );
};

export default Popup;
