import { IStringifyOptions, parse, ParsedQs, stringify } from 'qs';

export interface IObjectToSearchParamsOptions extends IStringifyOptions {
  noEmptyStrings?: boolean;
}

export function objectToSearchParams(values?: any): string {
  return stringify(values, { strictNullHandling: true });
}

export function searchParamsToObject(str?: string): ParsedQs {
  if (!str) {
    return {};
  }

  return parse(str, { strictNullHandling: true, ignoreQueryPrefix: true });
}
