import { useEffect, RefObject, useCallback } from 'react';

const useEnterEscKeyHandler = (
  ref: RefObject<HTMLElement> | null,
  callback: (key: 'Enter' | 'Escape') => void,
  disabled = false,
): void => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!ref) {
        return;
      }
      const { key } = event;
      if (key === 'Enter' || key === 'Escape') {
        callback(key);
      }
    },
    [ref],
  );

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (!disabled) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [disabled]);
};

export default useEnterEscKeyHandler;
