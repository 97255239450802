import { ClassNameMap, makeStyles } from '@mui/styles';
import { Color, ZIndex } from '../../app/consts/theme';
import { IDialogStylesProps } from './types';

const useStyles = (
  props: IDialogStylesProps,
): ((props?: unknown) => ClassNameMap<'layout' | 'closeButton' | 'content'>) =>
  makeStyles({
    layout: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      zIndex: props.canClose ? ZIndex.OVERLAY : ZIndex.POPUP,
      overflow: 'hidden',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.6)',
      fontSize: '14px',
    },
    closeButton: {
      position: 'absolute',
      top: 24,
      right: 24,
      cursor: 'pointer',
    },
    content: {
      position: 'relative',
      padding: props.canClose ? 32 : 24,
      borderColor: Color.WHITE0,
      borderRadius: 10,
      backgroundColor: Color.DARK5,
    },
  });

export default useStyles;
