import { makeStyles } from '@mui/styles';

export default makeStyles({
  root: {
    width: '100%',
  },
  tableRow: {
    display: 'flex',
    alignItems: 'center',
  },
  tableCell: {
    border: 'none',
    color: 'white',
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
  },
  userIcon: {
    width: 32,
    height: 32,
    marginLeft: 12,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: 16,
  },
  userName: {
    maxWidth: 200,
    textTransform: 'capitalize',
  },
  tableContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  inputSearch: {
    paddingTop: 20,
    paddingLeft: 24,
  },
  notFound: {
    fontSize: 18,
    color: '#6D747D',
  },
});
