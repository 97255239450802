import { IApplicationUser, RoleTypesEnum } from 'app/store/ApplicationStore/types';
import useStores from 'app/store/RootStore';
import useStyles from 'pages/Applications/View/components/UsersManagement/UserManagementMenu/styles';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Menu, MenuItem } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../../../../../components/DropDownButton/assets/arrow-down.svg';

export interface IProps {
  appId: string;
  user: IApplicationUser;
}

const UserManagementMenu: FC<IProps> = (props) => {
  const { appId, user } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles({ open });
  const { applicationStore } = useStores();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickModerator = async () => {
    if (user.role === RoleTypesEnum.Moderator) return;
    await applicationStore.changeApplicationUserRole(appId, user.id, {
      role: RoleTypesEnum.Moderator,
    });
    handleClose();
  };

  const handleClickUser = async () => {
    if (user.role === RoleTypesEnum.User) return;
    await applicationStore.changeApplicationUserRole(appId, user.id, {
      role: RoleTypesEnum.User,
    });
    handleClose();
  };

  const handleClickRemove = async () => {
    await applicationStore.removeApplicationUser(appId, user.id);
    handleClose();
  };

  return (
    <>
      <Button
        className={classes.button}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FormattedMessage id={user.role} />
        <div className={classes.arrowDown}>
          <ArrowDownIcon />
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        classes={{ paper: classes.menu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuItem} onClick={handleClickModerator}>
          <FormattedMessage id="moderator" />
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleClickUser}>
          <FormattedMessage id="user" />
        </MenuItem>
        <MenuItem style={{ color: 'red' }} className={classes.menuItem} onClick={handleClickRemove}>
          <FormattedMessage id="remove" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserManagementMenu;
