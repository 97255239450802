import { makeStyles } from '@mui/styles';
import { Color, Font } from '../../../app/consts/theme';

const useStyles = makeStyles({
  layout: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
    color: Color.WHITE,
    fontFamily: Font.BRAND,
    paddingBottom: 28,
  },
  section: {
    color: Color.WHITE3,
  },
  sectionItem: {
    marginTop: 10,
  },
  helpIcon: {
    padding: '0 !important',
    marginTop: 4,
  },
});

export default useStyles;
