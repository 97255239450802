import React from 'react';
import useStyles from './styles';
import { IPageLayoutProps } from './types';

const PageLayout: React.FC<IPageLayoutProps> = (props) => {
  const { disablePadding = false, children } = props;
  const classes = useStyles();

  return <div className={`${classes.layout} ${disablePadding ? classes.disablePadding : null}`}>{children}</div>;
};

export default PageLayout;
