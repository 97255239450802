import React from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from './consts/routes';
import Applications from '../pages/Applications';
import ApplicationView from '../pages/Applications/View';
import Account from '../pages/Account';

export default [
  {
    path: '/',
    exact: true,
    component: (): JSX.Element => (
      <Redirect to={`/${Routes.Applications}`}>
        <Applications />
      </Redirect>
    ),
  },
  {
    path: `/${Routes.Applications}`,
    exact: true,
    component: (): JSX.Element => <Applications />,
  },
  {
    path: `/${Routes.Applications}/create`,
    exact: true,
    component: (): JSX.Element => <ApplicationView create />,
  },
  {
    path: `/${Routes.Applications}/:id`,
    exact: true,
    component: (): JSX.Element => <ApplicationView />,
  },
  {
    path: `/${Routes.Profile}`,
    exact: true,
    component: (): JSX.Element => <Account />,
  },
];
