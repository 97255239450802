import React from 'react';
import useStyles from './styles';

const Layout: React.FC = (props) => {
  const { children } = props;
  const classes = useStyles(props);

  return <div className={classes.wrapper}>{children}</div>;
};

export default Layout;
