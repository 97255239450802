import { makeStyles } from '@mui/styles';
import userImage from '../../../../assets/icons/user.svg';

export default makeStyles({
  userButtonLayout: {
    position: 'absolute',
    right: 36,
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userIcon: {
    width: 32,
    height: 32,
    marginLeft: 12,
    backgroundImage: (props: { icon?: string }) => `url(${props.icon || userImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: 16,
  },
  userName: {
    maxWidth: 200,
  },
});
